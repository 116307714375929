<template>
    <div class="tea_index">
        <el-container>
            <el-header height="50px">
                <div class="tea_headerss">
                    <div class="tea_header_lss">
                        <span>ICD创意价值设计 —— 讲师端</span>
                    </div>
                    <div class="tea_header_c">
                        <!-- <span>西安翻译学院</span> -->
                    </div>
                    <div class="tea_header_rss clearfix">
                        <div class="menu_oness fl" v-show="database">
                            <el-tooltip class="item" effect="dark" content="资料库" placement="bottom">
                                <i class="iconfonts icons-icon_folder" @click="drawers()"></i>
                            </el-tooltip>
                        </div>
                        <div class="tea_he_imgss fl">
                            <img  :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">
                    
                            <span>{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</span>
                        </div>
                        <div class="tea_exitss fl" @click="exits()">
                            <i class="el-icon-switch-button"></i>
                            <span>退出</span>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-main class="tea_main">
                <router-view @func="getMsgid"></router-view>
            </el-main>
            <!-- <el-footer height="30px">
                <span>
                    © 2022.ICD创意价值设计 All rights reserved.
                </span>
            </el-footer> -->
        </el-container>
        <!-- 资源库抽屉 -->
        <div class="drawerBox">
            <el-drawer
                size="450px"
                title="资料库"
                :visible.sync="drawer"
                :show-close="false"
                :with-header="true">
                <span class="addBox" @click="addBox()">
                    +&nbsp;&nbsp; 添加
                </span>
                <div class="mu_list">
                    <ul v-show="istrue==1">
                        <li v-for="(item,index) in drawList" :key="index" v-show="drawList.length!=0">
                            <div class="bm-drake-box">
                                <div class="mu-item">
                                    <div class="mu-item-left">
                                        <div class="mu-avatar">
                                            <div class="mu-avatar-inner">
                                                <img :src="item.thumb" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mu-item_item clearfix">
                                        <div class="item_left fl">
                                            <span @click="viewBox(item)">{{item.name}}</span>
                                        </div>
                                    </div>
                                    <hr class="mu-divider">
                                </div>
                                <div class="mu-icon-menu">
                                    <div style="position: relative;">
                                        <button class="mu-icon-button">
                                            <i class="iconfonts icons-shanchu material-icons" @click="deleBtn(item)"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div class="default-wrapper" v-show="drawList.length==0">
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>资料库</span></p>
                            <button class="mu-raised-button" @click="addBox()">
                                添加一条
                            </button>
                        </div>
                    </ul>
                    <!-- 添加 -->
                    <div class="addshow" v-show="istrue==2">
                        <div class="add_upload" v-if="fileList.length==0">
                            <el-upload
                                class="upload-demo"
                                drag
                                :show-file-list='false'
                                :action="this.$store.state.uploads"
                                accept=".jpg, .jpeg, .png, .mp4, .pdf,"
                                :on-success="handleAvatarSuccess"
                                :limit="1"
                                :file-list="fileList"
                                multiple>
                                    <div class="tips-box"><p class="upload-tips"><span>+</span>上传资源<br></p> <p>支持拖拽上传</p></div>
                                    <div class="text-row"><p>支持资源 pdf / jpg / mp4</p></div>
                            </el-upload>
                        </div>
                        <div class="add_upload" v-else>
                            <div class="uploading">
                                <p><span @click="delBox()">删除</span></p>
                                <div class="data-box">
                                   <img src="./../../assets/img/ppt.svg" class="icon">
                                    <p class="file-name">{{form.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="mu-text-field">
                            <i class="mu-text-field-icon mu-icon material-icons iconfonts icons-biaoqian"></i>
                            <div class="mu-text-field-content">
                                <el-input v-model="inputs" placeholder="资源名称" size="small" @focus="focus" @blur="blur"></el-input>
                                <div><hr class="mu-text-field-line"> <hr class="mu-text-field-focus-line" :class="focunum==1?'focues':''"></div>
                            </div>
                        </div>
                        <div class="btn-wrapper">
                            <el-button type="info" size="small" @click="cancel()">取 消</el-button>
                            <el-button v-if="fileList.length==0" type="info" size="small" disabled>确 定</el-button>
                            <el-button v-else type="primary" size="small" @click="docadd()">确 定</el-button>
                        </div>
                    </div>
                </div>
            </el-drawer>
        </div>
        <!-- 弹窗 -->
        <div class="video_popup">
            <el-dialog
                title="查看详情"
                :visible.sync="dialogVisible"
                width="800px"
                :before-close="handleClose"
                :show-close="false"
                top="10vh"
            >
            <i class="el-icon-close closei" @click="closei()"></i>
                <div class="cd_video" v-if="videonum==1">
                    <video-player
                        class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions"
                        @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                        @ended="onPlayerEnded($event)"
                    ></video-player>
                </div>
                <div class="pdf_div" v-if="videonum==2 || videonum==3">
                    <el-button-group>
                        <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一页</el-button>
                        <el-button type="primary" size="mini" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </el-button-group>
                    <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
                    <pdf
                        :page="pageNum"
                        :src="url"
                        @progress="loadedRatio = $event"
                        @num-pages="pageTotalNum=$event" 
                    ></pdf>
                </div>
                <div class="photo_div" v-if="videonum==4">
                    <img :src="imageUrl" alt="">
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import pdf from 'vue-pdf'
export default {
    components: {
        videoPlayer,
        pdf
    },
    data() {
        return {
            database:false,
            istrue:1,
            fileList:[],
            drawList:[],
            form:{},
            inputs:'',
            focunum:0,
            drawer:false,
            dialogVisible:false,
            imageUrl:'',
            videonum:0,
            url: '',
            pageNum: 1,
            pageTotalNum: 1, // 总页数
            loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                width: "100%",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                {
                    type: "video/mp4", // 类型
                    src: "" // url地址
                },
                ],
                poster: '', // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        }
    },
    // directives: {
    //     close: {
    //         inserted(el, binding, vnode) {
    //             window.onclick = function(e) {
    //                 if (!el.contains(e.target)) {
    //                     vnode.context.deletes = false;
    //                 }
    //             };
    //         }
    //     }
    // },
    methods: {
         getMsgid(data){
            this.id = data
        },
        // 打开资料库
        drawers(){
            this.axios.TeaDocList({
                token:this.$store.state.token,
                id:this.id
            }).then(res=>{
                if(res.data.code==0){
                    this.drawList = res.data.data
                    this.drawer = true
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 下载
        download(item){
            window.open(item.url, "_blank");
        },  
        deleBtn(item){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.TeaDocDel({
                    id:item.id,
                    token:this.$store.state.token
                }).then(res=>{
                    if(res.data.code==0){
                        this.drawers()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //添加
        docadd(){
            this.axios.TeaDocAdd({
                token:this.$store.state.token,
                id:this.id,
                name:this.inputs,
                url:this.form.response.data
            }).then(res=>{
                if(res.data.code==0){
                    this.$message.success('添加成功')
                    this.istrue = 1
                    this.fileList = []
                    this.inputs = ''
                    this.drawers()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 上传文件
        handleAvatarSuccess(res,file,fileList){
            this.fileList = fileList
            this.form = file
            this.inputs = file.name
        },
        //删除上传文件
        delBox(){
            this.fileList = []
            this.inputs = ''
        },
        addBox(){
            this.istrue = 2
        },
        cancel(){
            this.istrue = 1
            this.fileList = []
        },
        // 获取焦点
        focus(){
            this.focunum = 1
        },
        // 失去焦点
        blur(){
            this.focunum = 0
        },
        getPath(){
            if(this.$route.name == 'Coursedetails'){
                this.database=true
            }else{
                this.database=false
            }
        },
        exits(){
            this.axios.userlogout({
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push('/login')
                    localStorage.clear()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 上一页
        prePage() {
            let page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },
        // 下一页
        nextPage() {
            let page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        },
        // 播放回调
        onPlayerPlay(player) {
            console.log("player play!", player);
        },
        // 暂停回调
        onPlayerPause(player) {
            console.log("player pause!", player);
        },
        // 视频播完回调
        onPlayerEnded($event) {
            console.log(player);
        },
        closei() {
            this.dialogVisible = false;
            this.playerOptions.sources[0].src = '';
            this.url = ''
            this.videonum = 0
        },
        // 资料库预览
        viewBox(item){
            if(item.type==1){
                this.videonum = 2
                this.url = item.url
            }else if(item.type==2){
                this.videonum = 4
                this.imageUrl = item.url
            }else if(item.type==3){
                this.videonum = 1
                this.playerOptions.sources[0].src = item.url;
            }
            this.dialogVisible = true
            this.pageNum = 1
        },
        handleClose(done){

        }
    },
    watch:{
        $route(to,from){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    }
}
</script>
<style scoped>
@import './../../assets/css/teacher/index.css';
</style>
<style>
/* 资源库抽屉 */
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.add_upload .el-upload{
    width: 100%;
}
.add_upload .el-upload-dragger{
    width: 100%;
    height: 140px;
    border: 1px dashed rgba(40, 120, 255, 0.5);
    background-color: rgba(40, 120, 255, 0.04);
}
.mu-text-field-content .el-input__inner{
    border: none;
    padding: 0;
}
.tea_index .el-header{
    background-color: #363b3e;
}
.tea_index .el-container{
    height: 100%;
}
.tea_index .el-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
/* 弹窗 */
.video_popup .el-dialog__header {
  padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.video_popup .el-dialog__body{
    background-color: #fff;
}
.video_popup .el-dialog__title {
    font-size: 16px;
    font-weight: bold;
  color: white;
}
.video_popup .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
.video_popup .el-dialog__headerbtn {
  top: 15px;
}
.cd_video .video-js{
    height: 100%!important;
}
.cd_video .video-js .vjs-mute-control{
    padding-left: 0;
}
.cd_video .video-js .vjs-control{
    width: 2.5em;
}
</style>